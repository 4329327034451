<template>
  <v-layout>
    <v-flex>
      <h2>Zahlungsjahre die erzeugt werden</h2>
      <v-layout row>
        <v-flex xs4>
          <yearpicker
            :hide-details="true"
            label="Ältestes Zahlungsjahr *"
            v-model="paymentYear"
          />
        </v-flex>
        <v-flex class="d-flex align-center justify-start" xs8>
          <div>
            <v-icon color="accent" style="vertical-align: bottom">info</v-icon>
            <template v-if="createdYears.length">
              Die folgenden Zahlungsjahre werden erzeugt:
              {{ createdYears.join(', ') }}
            </template>
            <template v-else> Es werden keine Zahlungsjahre erzeugt. </template>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Yearpicker from '@/components/dates/Yearpicker'

export default {
  name: 'MasterdataSelectCreatedPaymentyears',
  components: { Yearpicker },
  props: ['value'],
  data() {
    return {
      paymentYear: null,
    }
  },

  computed: {
    createdYears() {
      let year = Number(this.paymentYear)
      const currentYear = Number(new Date().getFullYear())
      if (Number.isNaN(year) || year < 1990 || year > currentYear) {
        return []
      }
      const years = []
      while (year <= currentYear) {
        years.push(year)
        year++
      }
      return years
    },
  },
  methods: {
    saveYears() {
      this.$emit('input', this.createdYears)
    },
  },
  watch: {
    paymentYear: 'saveYears',
  },
}
</script>

<style scoped></style>
