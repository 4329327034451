<template>
  <v-dialog persistent v-model="isDialog" width="500">
    <v-card class="content">
      <v-card-title primary-title><h3>Stammdaten kopieren</h3></v-card-title>
      <v-card-text>
        <v-alert :value="true" color="blue-grey darken-1" type="warning">
          Um IHK Zugehörigkeiten und Hebesätze zu übernehmen müssen zuerst die
          erstellten Zahlungsjahre definiert werden.
        </v-alert>
      </v-card-text>
      <v-card-text>
        <search-masterdata-typeahead
          @itemSelected="itemDataChanged"
          class="mb-4"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isDialog = false">Abbrechen</v-btn>
        <v-btn
          :loading="loading"
          :disabled="!id"
          color="primary"
          @click="copyData"
          >Übernehmen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchMasterdataTypeahead from '@/components/SearchMasterdataTypeahead'
import masterdataApi from '@/api/masterdataApi'
import { isDialog } from '@/mixins/isDialog'

export default {
  name: 'MasterdataCopyModal',
  components: { SearchMasterdataTypeahead },
  mixins: [isDialog],
  data: () => ({ masterData: null, id: null, loading: false }),
  methods: {
    itemDataChanged(id) {
      this.id = id
    },
    async copyData() {
      this.loading = true
      try {
        this.masterData = await masterdataApi.getEntry(this.id)
        this.$emit('copyData', this.masterData)
        this.isDialog = false
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
