export class MasterData {
  constructor() {
    this.municipalRate = []
    this.ihkName = []
    this.company_id = null
    this.ags = ''
    this.zip = ''
    this.agsOld = []
    this.isAgsOld = false
    this.municipality = ''
    this.parentMunicipalityAgs = ''
    this.addressAdministrativeMunicipality = {
      name: '',
      street: '',
      city: '',
      zip: '',
    }
    this.contact = [
      {
        title: 'Veranlagung',
        contactName: '',
        phone: '',
        fax: '',
        email: '',
      },
      { title: 'Kasse', contactName: '', phone: '', fax: '', email: '' },
    ]
    this.referenceNumber = ''
    this.bank = {
      iban: '',
      bic: '',
      bType: '',
    }
    this.creditor = ''
    this.suburbanisedAt = ''
    this.belongedToGrid = []
    this.smallCrossBorderTraffic = false
    this.uniformMunicipalRateSince = ''
    this.payments = []
    this.notes = []
  }
}
