<template>
  <app-layout>
    <v-layout>
      <v-flex xs12 md12 lg12 v-if="masterData">
        <v-container grid-list-md>
          <v-layout>
            <v-flex>
              <h2 v-if="create">Neuer Stammdatensatz</h2>
              <div v-else>
                <h2>
                  {{ masterData.ags }} —
                  {{ masterData.municipality }}
                </h2>
                <v-chip disabled>{{ masterData.company.name }}</v-chip>
              </div>
            </v-flex>
            <v-spacer />
            <template v-if="create">
              <v-btn @click="openCopyMasterdata" color="primary"
                >Stammdaten kopieren
              </v-btn>
              <v-btn @click="saveItem" color="primary"
                >Stammdatensatz erstellen
              </v-btn>
            </template>
            <modal-masterdata-diff
              v-else
              :changed="masterData"
              :diff="diff"
              :original="diffOriginalCopy"
              @save="saveItem"
            />

            <master-data-view-menu
              v-if="!create && isEditor"
              :has-diff="hasDiff"
              :is-creating="create"
              :master-data="masterData"
              @removeAsSubMunicipality="
                modalConfirmModalRemoveAsSubMunicipality = true
              "
              @resetData="resetData"
              @setAsSubMunicipality="selectMunicipalityDialog = true"
            />
          </v-layout>
        </v-container>
        <v-container v-if="validationErrors">
          <ValidationErrors :errors="validationErrors" />
        </v-container>
        <master-data-view-content
          :children="children"
          :disabled="!isEditor"
          :is-creating="create"
          :master-data="masterData"
        />

        <v-container grid-list-md>
          <template v-if="create">
            <masterdata-select-created-paymentyears
              class="mb-3"
              @input="(val) => (paymentYears = val)"
            />
            <masterdata-create-municipality-ihk-rate
              v-if="paymentYears.length"
              :master-data="masterData"
              :payment-years="paymentYears"
              class="mb-3"
            />
          </template>

          <v-layout>
            <v-spacer />
            <v-btn @click="saveItem" color="primary" v-if="create"
              >Stammdatensatz erstellen
            </v-btn>

            <modal-masterdata-diff
              v-else
              :changed="masterData"
              :diff="diff"
              :original="diffOriginalCopy"
              @save="saveItem"
            />
          </v-layout>
        </v-container>

        <ConfirmModal
          v-model="modalConfirmModalRemoveAsSubMunicipality"
          :ok-action="removeAsSubMunicipality"
          :text="`Wollen sie wirklich die Eingemeindung des Ortes ${masterData.municipality} aufheben? Diese Aktion kann nicht rückgänging gemacht werden!`"
          title="Eingemeinung aufheben"
        />

        <modal-set-as-submunicipality-of
          v-model="selectMunicipalityDialog"
          :master-data="masterData"
          @itemSelected="addParentMunicipality"
        />

        <masterdata-copy-modal
          v-if="masterData"
          v-model="copyDataModal"
          @copyData="copyData"
        />
      </v-flex>
    </v-layout>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import masterDataApi from '@/api/masterdataApi'
import MasterDataViewContent from '@/pages/masterDataView/MasterDataViewContent'
import { hasDiff } from '@/mixins/hasDiff'
import { lockRoutingWhenChanged } from '@/mixins/lockRoutingWhenChanged'
import ModalMasterdataDiff from '@/pages/masterDataView/ModalMasterdataDiff'
import MasterDataViewMenu from '@/pages/masterDataView/MasterDataViewMenu'
import { MasterData } from '@/pages/masterDataView/MasterData'
import ModalSetAsSubmunicipalityOf from '@/pages/masterDataView/ModalSetAsSubmunicipalityOf'
import MasterdataSelectCreatedPaymentyears from '@/pages/masterDataView/MasterdataSelectCreatedPaymentyears'
import ValidationErrors from '@/components/ValidationErrors'
import MasterdataCreateMunicipalityIhkRate from '@/pages/masterDataView/MasterdataCreateMunicipalityIhkRate'
import MasterdataCopyModal from '@/pages/masterDataView/MasterdataCopyModal'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'MasterDataView',
  components: {
    ConfirmModal,
    MasterdataCopyModal,
    MasterdataCreateMunicipalityIhkRate,
    ValidationErrors,
    MasterdataSelectCreatedPaymentyears,
    ModalSetAsSubmunicipalityOf,
    MasterDataViewMenu,
    ModalMasterdataDiff,
    MasterDataViewContent,
    AppLayout,
  },
  mixins: [hasDiff, lockRoutingWhenChanged],
  props: ['id', 'create'],
  data() {
    return {
      masterData: null,
      paymentYears: [],
      children: [],
      selectMunicipalityDialog: false,
      validationErrors: null,
      copyDataModal: false,
      modalConfirmModalRemoveAsSubMunicipality: false,
    }
  },
  computed: {
    isEditor() {
      return this.$can(this.$permissions.changeMasterdata)
    },
    diffObserved() {
      return this.masterData
    },
  },
  methods: {
    openCopyMasterdata() {
      if (this.paymentYears.length === 0) {
        this.$root.setSnackbar(
          'error',
          `Bitte erst ältestes Zahlungsjahr ausfüllen`
        )
      } else {
        this.copyDataModal = true
      }
    },
    copyData(data) {
      const copyByYears = (orig, copy) =>
        orig.map(({ year }) =>
          copy.find(({ year: y }) => Number(y) === Number(year))
        )

      const { ihkName, municipalRate } = this.masterData

      // special processing for ihkName and municipalRate

      this.masterData.ihkName = copyByYears(ihkName, data.ihkName)
      this.masterData.municipalRate = copyByYears(
        municipalRate,
        data.municipalRate
      )

      // Handpick elements which can be copied
      ;[
        'addressAdministrativeMunicipality',
        'contact',
        'ags',
        'agsOld',
        'bank',
        'creditor',
        'isAgsOld',
        'municipality',
        'parentMunicipalityAgs',
        'smallCrossBorderTraffic',
        'suburbanisedAt',
        'uniformMunicipalRateSince',
        'zip',
      ].forEach((key) => {
        this.$set(this.masterData, key, data[key])
      })
    },
    diffFilter(diff) {
      if (this.create) {
        return {} // do not check for diffs for new objects.
      }
      const excludedPaths = [
        'parent',
        'parentMunicipalityAgs',
        'notes',
        'referenceNumber',
        'oldReferenceNumbers',
      ]
      excludedPaths.forEach((el) => delete diff[el])

      return diff
    },
    async getData() {
      if (this.create) {
        this.masterData = new MasterData()
        if (!this.$store.getters.currentCompany) {
          // might be some concurrency involved here,
          // so we better watch the value when its not existent yet.
          const unwatch = this.$watch(
            '$store.getters.currentCompany',
            (company) => {
              this.masterData.company_id = company.id
              unwatch()
            }
          )
        } else {
          this.masterData.company_id = this.$store.getters.currentCompany.id
        }
      } else {
        this.masterData = await masterDataApi.getEntry(this.id)
        this.setDiffObservable(this.diffObserved)
        await this.$store.dispatch(
          'setCurrentCompany',
          this.masterData.company_id
        )
        this.children = await masterDataApi.getChildrenOf(this.id)
      }
    },
    async saveItem() {
      this.validationErrors = null
      if (this.create) {
        try {
          const data = {
            ...this.masterData,
            paymentYears: this.paymentYears,
          }
          const response = await masterDataApi.createEntry(data)
          this.$root.setSnackbar('success', 'Eintrag erfolgreich erstellt')
          this.masterData = null
          this.$router.replace({
            name: 'MasterDataView',
            params: { id: response.id },
          })
        } catch (e) {
          this.validationErrors = e.errorObject
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      } else {
        try {
          await masterDataApi.updateEntry(this.id, this.masterData)
          await this.getData() // refetch data and reset everything
          this.$root.setSnackbar('success', 'Eintrag aktualsiert')
        } catch (e) {
          this.validationErrors = e.errorObject
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }
    },

    resetData() {
      this.masterData = JSON.parse(JSON.stringify(this.diffOriginalCopy))
    },
    async addParentMunicipality(formData) {
      await masterDataApi.setParentMunicipality(this.id, formData)
      await this.getData()
    },
    async removeAsSubMunicipality() {
      await masterDataApi.removeParentMunicipality(this.id)
      await this.getData()
    },
  },
  watch: {
    id: {
      handler: 'getData',
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
