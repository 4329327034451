<template>
  <!-- -->
  <v-layout>
    <v-flex>
      <h2>IHK Zugehörigkeit/Hebesätze</h2>
      <v-layout :key="year" row v-for="year in paymentYears">
        <!-- eslint-disable vue/valid-v-model -->
        <v-flex>
          <v-text-field :value="year" disabled label="Zahlungsjahr" readonly />
        </v-flex>
        <v-flex>
          <v-text-field
            :label="`IHK Zugeh. ${year} *`"
            v-model="entryForYear(masterData.ihkName, year).value"
          />
        </v-flex>
        <v-flex>
          <v-text-field
            :label="`Hebesatz ${year} *`"
            v-model="entryForYear(masterData.municipalRate, year).value"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'MasterdataCreateMunicipalityIhkRate',
  props: {
    masterData: {
      type: Object,
      required: true,
    },
    paymentYears: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      paymentYear: null,
    }
  },

  methods: {
    syncEntries(years) {
      this.masterData.ihkName = []
      this.masterData.municipalRate = []
      ;[this.masterData.ihkName, this.masterData.municipalRate].forEach(
        (array) => {
          years.forEach((year) => {
            array.push({ year, value: '' })
          })
        }
      )
    },
    entryForYear(array, year) {
      return array.find((el) => String(el.year) === String(year))
    },
  },
  watch: {
    paymentYears: {
      handler: 'syncEntries',
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
